import { create } from "zustand"

interface UserImageState {
  localAvatar: string | null
  avatarUploadStatus: "idle" | "uploading" | "uploaded"
  setAvatarUploadStatus: (status: "idle" | "uploading" | "uploaded") => void
  setLocalAvatar: (localAvatar: string | null) => void
  localBanner: string | null
  bannerUploadStatus: "idle" | "uploading" | "uploaded"
  setBannerUploadStatus: (status: "idle" | "uploading" | "uploaded") => void
  setLocalBanner: (localBanner: string | null) => void
}

const useUserImageStore = create<UserImageState>((set) => ({
  localAvatar: null,
  avatarUploadStatus: "idle",
  setAvatarUploadStatus: (status: "idle" | "uploading" | "uploaded") =>
    set({ avatarUploadStatus: status }),
  setLocalAvatar: (localAvatar: string | null) => set({ localAvatar }),
  localBanner: null,
  bannerUploadStatus: "idle",
  setBannerUploadStatus: (status: "idle" | "uploading" | "uploaded") =>
    set({ bannerUploadStatus: status }),
  setLocalBanner: (localBanner: string | null) => set({ localBanner }),
}))

export default useUserImageStore
