"use client";

import React from "react";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { cn } from "@/lib/utils";
import { ActorSnapshot, User } from "@/graphql/generated/types";
import { useUser } from "@/contexts/user";
import useUserImageStore from "@/store/useUserImageStore";
const UserAvatar = ({
  user,
  className,
  fallbackSize,
  fallbackClassName,
  sizes,
  variant = "default",
  ...props
}: {
  user?: {
    id?: User["id"];
    avatarUrls?: User["avatarUrls"];
    avatarUrl?: ActorSnapshot["avatarUrl"];
    username?: User["username"];
  } | null;
  className?: string;
  sizes: string;
  fallbackSize?: number;
  variant?: "default" | "snapshot";
  fallbackClassName?: string;
} & React.ComponentProps<typeof Avatar>) => {
  const {
    user: authUser
  } = useUser();
  const localAvatar = useUserImageStore(state => state.localAvatar);
  const avatarUploadStatus = useUserImageStore(state => state.avatarUploadStatus);
  let small = avatarUploadStatus === "uploading" && user?.id === authUser?.id && localAvatar ? localAvatar : user?.avatarUrls?.small;
  let medium = avatarUploadStatus === "uploading" && user?.id === authUser?.id && localAvatar ? localAvatar : user?.avatarUrls?.medium;
  if (variant === "snapshot") {
    small = user?.avatarUrl;
    medium = user?.avatarUrl;
  }
  const srcSet = small && medium ? `${small} 120w, ${medium} 360w` : undefined;
  return <Avatar className={cn(className)} {...props} data-sentry-element="Avatar" data-sentry-component="UserAvatar" data-sentry-source-file="UserAvatar.tsx">
      <AvatarImage src={small ?? ""} srcSet={srcSet} sizes={sizes} alt={user?.username ?? "user"} data-sentry-element="AvatarImage" data-sentry-source-file="UserAvatar.tsx" />
      <AvatarFallback className={cn("bg-white/[4%]", fallbackClassName)} data-sentry-element="AvatarFallback" data-sentry-source-file="UserAvatar.tsx">
        <svg width={fallbackSize || 102} height={fallbackSize || 102} viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="UserAvatar.tsx">
          <path fillRule="evenodd" clipRule="evenodd" d="M34 29.75C34 25.2413 35.7911 20.9173 38.9792 17.7292C42.1673 14.5411 46.4913 12.75 51 12.75C55.5087 12.75 59.8327 14.5411 63.0208 17.7292C66.2089 20.9173 68 25.2413 68 29.75C68 34.2587 66.2089 38.5827 63.0208 41.7708C59.8327 44.9589 55.5087 46.75 51 46.75C46.4913 46.75 42.1673 44.9589 38.9792 41.7708C35.7911 38.5827 34 34.2587 34 29.75ZM34 55.25C28.3641 55.25 22.9591 57.4888 18.974 61.474C14.9888 65.4591 12.75 70.8642 12.75 76.5C12.75 79.8815 14.0933 83.1245 16.4844 85.5156C18.8755 87.9067 22.1185 89.25 25.5 89.25H76.5C79.8815 89.25 83.1245 87.9067 85.5156 85.5156C87.9067 83.1245 89.25 79.8815 89.25 76.5C89.25 70.8642 87.0112 65.4591 83.026 61.474C79.0409 57.4888 73.6358 55.25 68 55.25H34Z" fill="#E6E6FA" fillOpacity="0.5" data-sentry-element="path" data-sentry-source-file="UserAvatar.tsx" />
        </svg>
      </AvatarFallback>
    </Avatar>;
};
export default UserAvatar;