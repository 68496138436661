/* eslint-disable @next/next/no-img-element */
"use client";

import React, { useState, useEffect, useRef, Suspense, useCallback, useMemo } from "react";
import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList } from "@/components/ui/command";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { inter } from "@/app/fonts";
import { useApolloClient, useSuspenseQuery } from "@apollo/client";
import { useDebounce } from "@/hooks/useDebounce";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import PopoverContainer from "./PopoverContainer";
import { usePopover } from "@/hooks/usePopover";
import ButtonLoader from "./ButtonLoader";
import { addRecentSearch, getRecentSearches, getAllRecentSearches, removeRecentSearch, SearchType, SearchResultItem, UserSearchResult } from "@/utils/recentSearches";
import { useResponsive } from "@/hooks/useResponsive";
import { SearchBookResult, SearchBooksByTitleDocument, SearchUsersDocument } from "@/graphql/generated/types";
import BookCover from "./BookCover";
import { Button } from "../ui/button";
import { cn } from "@/lib/utils";
import AddBookDialog from "../book/AddBookDialog";
import UserAvatar from "./UserAvatar";

// Error boundary component for search results
function SearchErrorBoundary({
  children
}: {
  children: React.ReactNode;
}) {
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    const handleError = (event: ErrorEvent) => {
      console.error("Search error caught by error boundary:", event.error);
      setHasError(true);
    };
    window.addEventListener("error", handleError);
    return () => {
      window.removeEventListener("error", handleError);
    };
  }, []);
  if (hasError) {
    return <CommandEmpty className="text-center text-sm font-medium text-white">
        <div className="flex px-6 py-[18px] justify-center">
          Something went wrong with search. Please try again.
        </div>
      </CommandEmpty>;
  }
  return <>{children}</>;
}

// Search functionality types
export type SearchResultType = SearchBookResult | UserSearchResult;
interface SearchInputProps {
  placeholder?: string;
  onSelect?: (item: SearchResultType) => void;
  className?: string;
  hideRecentSearches?: boolean;
  containerClassName?: string;
  parentClassName?: string;
  itemClassName?: string;
  commandListClassName?: string;
  iconSize?: number;
  lockBackgroundScroll?: boolean;
  focusInput?: boolean;
  searchType?: SearchType;
  excludeUsers?: boolean;
  showAllResults?: boolean;
  disableLink?: boolean;
}
interface SearchResultsProps {
  debouncedQuery: string;
  searchType: SearchType;
  onSelect?: (item: SearchResultType) => void;
  itemClassName?: string;
  handleSeeAll?: () => void;
  showAllResults?: boolean;
  disableLink?: boolean;
}

// Book search document
const SEARCH_BOOKS_BY_TITLE = SearchBooksByTitleDocument;
// User search document
const SEARCH_USERS = SearchUsersDocument;
function SearchResults({
  debouncedQuery,
  searchType,
  onSelect,
  itemClassName,
  handleSeeAll,
  showAllResults = false,
  disableLink = false
}: SearchResultsProps) {
  const shouldSkip = debouncedQuery.trim() === "";
  const isUserSearch = searchType === "users";
  const isBookSearch = searchType === "books";

  // For user search, check if query starts with @ and remove it
  const queryForUsers = isUserSearch && debouncedQuery.startsWith("@") ? debouncedQuery.substring(1) : debouncedQuery;

  // Use the appropriate query based on search type
  const {
    data: bookData
  } = useSuspenseQuery(SEARCH_BOOKS_BY_TITLE, {
    variables: {
      title: debouncedQuery,
      pageSize: 24
    },
    skip: shouldSkip || !isBookSearch
  });
  const {
    data: userData
  } = useSuspenseQuery(SEARCH_USERS, {
    variables: {
      query: queryForUsers
    },
    skip: shouldSkip || !isUserSearch
  });

  // Handle different result types
  const bookResults = bookData?.searchBooks?.items || [];
  const userResults = userData?.searchUsers || [];

  // Determine which results to use
  const results = isUserSearch ? userResults : bookResults;
  const totalCount = isBookSearch ? bookData?.searchBooks?.pagination?.totalCount || 0 : results.length;

  // Create type-specific handler functions
  const handleBookSelect = useCallback((book: SearchBookResult) => {
    addRecentSearch(book, "books");
    if (onSelect) {
      onSelect(book);
    }
  }, [onSelect]);
  const handleUserSelect = useCallback((user: UserSearchResult) => {
    addRecentSearch(user, "users");
    if (onSelect) {
      onSelect(user);
    }
  }, [onSelect]);
  console.log(showAllResults);
  if (!debouncedQuery) {
    return null;
  }
  return <div className="flex flex-col h-full relative" data-sentry-component="SearchResults" data-sentry-source-file="SearchInput.tsx">
      {isBookSearch && <BookList books={bookResults} onSelect={handleBookSelect} itemClassName={itemClassName} emptyMessage="No results found" disableLink={disableLink} />}

      {isUserSearch && <UserList users={userResults} onSelect={handleUserSelect} itemClassName={itemClassName} emptyMessage="No users found" />}

      {(showAllResults || !onSelect) && totalCount > 5 && !isUserSearch && debouncedQuery.trim() !== "" && <div className="sticky -bottom-1 max-md:pb-10 max-md:pt-6 max-md:px-5 justify-end max-md:flex md:justify-center flex-1 w-full border-[#bdbdbd]/10 bg-[#121320]">
            <Button type="button" onClick={handleSeeAll} className="px-4 py-2.5 md:py-3.5 w-fit md:w-full text-sm font-semibold h-11 md:h-12 hover:bg-[#171824] justify-end md:justify-center bg-[#5069ce] md:bg-black/20 rounded-b-[6px]">
              See all {totalCount?.toLocaleString()} results
            </Button>
          </div>}
    </div>;
}
const SearchInput: React.FC<SearchInputProps> = ({
  placeholder,
  onSelect,
  hideRecentSearches,
  className,
  containerClassName,
  parentClassName,
  itemClassName,
  commandListClassName,
  lockBackgroundScroll,
  focusInput,
  excludeUsers = true,
  searchType = "books",
  showAllResults = false,
  disableLink = false
}) => {
  const router = useRouter();
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedQuery = useDebounce(searchQuery, 350);
  const pathname = usePathname();
  const [hasInteracted, setHasInteracted] = useState(false);
  const {
    isMobile
  } = useResponsive();
  const {
    isOpen,
    setIsOpen,
    ref
  } = usePopover(isMobile && lockBackgroundScroll);
  const inputRef = useRef<HTMLInputElement>(null);
  const [recentSearches, setRecentSearches] = useState<SearchResultItem[]>([]);

  // Determine search type based on query or explicit prop
  const effectiveSearchType = useMemo(() => {
    return searchType === "books" && searchQuery.startsWith("@") && !excludeUsers ? "users" : searchType;
  }, [searchType, searchQuery, excludeUsers]);
  const isUserSearch = effectiveSearchType === "users";
  const refreshRecentSearches = useCallback(() => {
    const recent = getRecentSearches(effectiveSearchType);
    setRecentSearches(recent);
  }, [effectiveSearchType]);
  const apolloClient = useApolloClient();
  useEffect(() => {
    refreshRecentSearches();
  }, [refreshRecentSearches]);
  useEffect(() => {
    setSearchQuery("");
    setHasInteracted(false);
    setIsOpen(false);
  }, [pathname, setIsOpen, setHasInteracted]);
  useEffect(() => {
    if (hasInteracted && (debouncedQuery.trim() !== "" || !hideRecentSearches && recentSearches.length > 0)) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [debouncedQuery, setIsOpen, recentSearches, hasInteracted, hideRecentSearches]);
  const handleInputFocus = useCallback(() => {
    setHasInteracted(true);
    if (!hideRecentSearches && debouncedQuery.trim() !== "" || recentSearches.length > 0) {
      setIsOpen(true);
    }
  }, [debouncedQuery, recentSearches, setIsOpen, hideRecentSearches]);
  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setHasInteracted(true);
    setSearchQuery(e.target.value);
  }, []);
  const handleSelect = useCallback((item: SearchResultType) => {
    setIsOpen(false);
    if (onSelect) {
      onSelect(item);
    }
  }, [setIsOpen, onSelect]);
  useEffect(() => {
    if (inputRef && focusInput) {
      inputRef.current?.focus({
        preventScroll: true
      });
      handleInputFocus();
    }
  }, [focusInput, handleInputFocus]);
  const handleSearch = () => {
    if (isUserSearch) {
      return;
    }
    if (searchQuery.trim() !== "") {
      apolloClient.cache.evict({
        fieldName: isUserSearch ? "searchUsers" : "searchBooks"
      });
      apolloClient.cache.gc();

      // Handle different search types
      if (isUserSearch) {
        const query = searchQuery.startsWith("@") ? searchQuery.substring(1) : searchQuery;
        router.push(`/search?q=${query}&type=users`);
      } else {
        router.push(`/search?q=${searchQuery}&type=books`);
      }
      setIsOpen(false);
    }
  };
  const placeholderText = useMemo(() => {
    return placeholder || (isUserSearch ? "Search users" : "Search by Title");
  }, [placeholder, isUserSearch]);
  return <div className={cn("relative w-full", inter.className, parentClassName)} ref={ref} data-sentry-component="SearchInput" data-sentry-source-file="SearchInput.tsx">
      <Input ref={inputRef} icon={<SearchIcon />} type="text" onFocus={handleInputFocus} value={searchQuery} onChange={handleInputChange} onKeyDown={e => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleSearch();
      }
    }} placeholder={placeholderText} className={cn(className)} data-sentry-element="Input" data-sentry-source-file="SearchInput.tsx" />

      <PopoverContainer isOpen={isOpen} style={{
      filter: "drop-shadow(0 5px 15px rgba(0, 5, 15, 0.35))"
    }} popoverClassName={cn("rounded-md absolute top-[60px] z-[110] w-full min-w-[236px] sm:max-h-[484px] max-w-[513px] border border-[#bdbdbd]/10 max-sm:bg-transparent sm:bg-[#121320] p-0 text-sm text-white", inter.className, containerClassName)} data-sentry-element="PopoverContainer" data-sentry-source-file="SearchInput.tsx">
        <Command shouldFilter={false} className={cn("font-inter bg-[#10111e] sm:bg-[#121320] h-full max-sm:rounded-none")} data-sentry-element="Command" data-sentry-source-file="SearchInput.tsx">
          <CommandList className={cn("custom-dropdown-scrollbar overflow-y-auto max-sm:h-[90vh] sm:max-h-[484px] ", commandListClassName)} data-sentry-element="CommandList" data-sentry-source-file="SearchInput.tsx">
            <SearchErrorBoundary data-sentry-element="SearchErrorBoundary" data-sentry-source-file="SearchInput.tsx">
              <Suspense fallback={<div className="px-6 py-3 w-full flex items-center justify-center text-center text-sm text-white">
                    <ButtonLoader />
                  </div>} data-sentry-element="Suspense" data-sentry-source-file="SearchInput.tsx">
                {!hideRecentSearches && debouncedQuery.trim() === "" ? <RecentSearches itemClassName={itemClassName} onSelect={handleSelect} /> : <SearchResults debouncedQuery={debouncedQuery} searchType={effectiveSearchType} onSelect={handleSelect} itemClassName={itemClassName} handleSeeAll={handleSearch} showAllResults={showAllResults} disableLink={disableLink} />}
              </Suspense>
            </SearchErrorBoundary>
          </CommandList>
        </Command>
      </PopoverContainer>
    </div>;
};
export default React.memo(SearchInput);
const SearchIcon = () => {
  return <>
      {" "}
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="max-sm:hidden" data-sentry-element="svg" data-sentry-source-file="SearchInput.tsx">
        <path d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="SearchInput.tsx" />
        <path d="M14.0016 14.0016L11.1016 11.1016" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="SearchInput.tsx" />
      </svg>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="sm:hidden" data-sentry-element="svg" data-sentry-source-file="SearchInput.tsx">
        <path fillRule="evenodd" clipRule="evenodd" d="M4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10C16 13.3137 13.3137 16 10 16C6.68629 16 4 13.3137 4 10ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C11.8487 18 13.551 17.3729 14.9056 16.3199L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L16.3199 14.9056C17.3729 13.551 18 11.8487 18 10C18 5.58172 14.4183 2 10 2Z" fill="white" data-sentry-element="path" data-sentry-source-file="SearchInput.tsx" />
      </svg>
    </>;
};

// Components for displaying books
interface BookListProps {
  books?: (SearchBookResult | null)[];
  onSelect?: (book: SearchBookResult) => void;
  onDelete?: (book: SearchBookResult) => void;
  itemClassName?: string;
  emptyMessage?: string;
  disableLink?: boolean;
}
const BookList: React.FC<BookListProps> = ({
  books,
  onSelect,
  onDelete,
  itemClassName,
  disableLink = false,
  emptyMessage = "No search results"
}) => {
  const handleSelect = React.useCallback((book: SearchBookResult) => {
    if (onSelect) {
      onSelect(book);
    }
  }, [onSelect]);
  if (!books || books.length === 0) {
    return <CommandEmpty style={{
      filter: "drop-shadow"
    }} className="text-center text-sm font-medium text-white">
        <div className="flex px-6 items-center py-[18px] justify-center">
          {emptyMessage}
        </div>

        <div className="flex py-[9px] bg-black/20 border-t border-[#bdbdbd]/10 items-center justify-center gap-2">
          <span className="font-normal text-[#e6e6fa]/90">
            Can&apos;t find it?
          </span>

          <AddBookDialog trigger={<Button variant="link" className="text-white hover:no-underline flex items-center gap-1 font-semibold text-sm leading-[22px] px-2 py-1 rounded-[8px] bg-white/[4%]">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.0447 0.14209H4.74344C4.67527 0.136503 4.60691 0.133571 4.53851 0.133301C3.10364 0.133301 1.94043 1.29648 1.94043 2.73135C1.94048 2.80117 1.94322 2.87029 1.94866 2.9387H1.94043V16.5792C1.94043 17.2668 2.49783 17.8242 3.18545 17.8242H4.61396V14.9703C4.73834 15.0084 4.86771 15.0277 4.99779 15.0276H15.4625C16.1847 15.0276 16.7702 14.4421 16.7702 13.7198V1.86756C16.7702 0.914613 15.9977 0.14209 15.0447 0.14209Z" fill="#5069CE" />
                  <path d="M16.0289 2.38862C16.0289 1.61546 15.2994 0.983352 14.3779 0.935398V0.932129H4.53828C3.82085 0.932129 3.23926 1.37749 3.23926 1.92684C3.23926 2.47619 3.82085 2.92155 4.53828 2.92155V2.9386H11.668V16.6837L14.2655 16.9886C15.2742 16.9886 16.0289 14.9926 16.0289 14.0069V2.45787H16.0267C16.028 2.43492 16.0289 2.41186 16.0289 2.38862Z" fill="#DCE2E2" />
                  <path d="M13.7308 2.93848H4.61328V17.824H13.7308C14.4428 17.824 15.02 17.2468 15.02 16.5348V4.22762C15.02 3.51564 14.4428 2.93848 13.7308 2.93848Z" fill="#7187E1" />
                  <path d="M11.724 8.4516H7.0502C6.7706 8.4516 6.54395 8.22495 6.54395 7.94535V6.1835C6.54395 5.9039 6.7706 5.67725 7.0502 5.67725H11.724C12.0036 5.67725 12.2303 5.9039 12.2303 6.1835V7.94535C12.2303 8.22495 12.0036 8.4516 11.724 8.4516Z" fill="#32434F" />
                </svg>

                <span>Add Book</span>
              </Button>} />
        </div>
      </CommandEmpty>;
  }
  return <ScrollArea data-sentry-element="ScrollArea" data-sentry-component="BookList" data-sentry-source-file="SearchInput.tsx">
      <CommandGroup className="p-0" data-sentry-element="CommandGroup" data-sentry-source-file="SearchInput.tsx">
        {books.map(book => {
        if (!book) {
          return null;
        }
        return <div key={book?.id} className="flex w-full flex-col">
              <CommandItem className={cn("h-[96px] cursor-pointer items-start w-full flex-1 border-b border-b-[#bdbdbd]/10 border-r-4 border-r-transparent bg-[#121320] px-4 py-3 leading-5 text-white aria-selected:bg-[#171824] aria-selected:text-white", books.length < 4 && "sm:aria-selected:border-r-[#5069ce]", itemClassName)} onSelect={() => handleSelect(book)}>
                {onSelect !== undefined && disableLink ? <div className={cn("flex w-full items-center justify-between gap-5")}>
                    <div className="flex flex-grow items-start gap-4 font-medium">
                      <div className="h-[72px] w-[48px] aspect-[1/1.5] rounded-[4px]">
                        <BookCover book={book} fallBackSize={28} useDivInsteadOfLink={true} sizes="48px" fallbackClassName="rounded-[4px]" className="h-full w-full rounded-[4px] object-cover object-center" style={{
                    filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.35))"
                  }} />
                      </div>
                      <div className="flex flex-col mt-2 gap-2">
                        <span className="text-sm font-bold text-white -tracking-[1%]">
                          {book?.title && book?.title?.length > 27 ? `${book?.title.slice(0, 27)}...` : book.title}
                        </span>
                        {book.authors && <span className="text-xs font-medium text-[#e6e6fa]/70">
                            {book.authors}
                          </span>}
                      </div>
                    </div>
                  </div> : <Link href={book?.slug ? `/books/${book.slug}` : ""} className={cn("flex w-full items-center justify-between gap-5")}>
                    <div className="flex flex-grow items-start gap-4 font-medium">
                      <div className="h-[72px] w-[48px] aspect-[1/1.5] rounded-[4px]">
                        <BookCover fallbackClassName="rounded-[4px]" book={book} useDivInsteadOfLink={true} sizes="48px" fallBackSize={28} className="h-full w-full rounded-[4px] object-cover object-center" style={{
                    filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.35))"
                  }} />
                      </div>
                      <div className="flex flex-col mt-2 gap-2">
                        <span className="text-sm font-bold text-white -tracking-[1%]">
                          {book?.title && book.title.length > 27 ? `${book?.title.slice(0, 27)}...` : book.title}
                        </span>
                        {book.authors && <span className="text-xs font-medium text-[#e6e6fa]/70">
                            {book.authors}
                          </span>}
                      </div>
                    </div>
                  </Link>}
                {onDelete && <button onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onDelete(book);
            }} className="ml-2 text-[#e6e6fa] hover:text-white focus:outline-none" aria-label={`Remove ${book.title} from recent searches`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_3574_49412)">
                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0_3574_49412">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>}
              </CommandItem>
            </div>;
      })}
      </CommandGroup>
    </ScrollArea>;
};

// Components for displaying users
interface UserListProps {
  users?: (UserSearchResult | null)[];
  onSelect?: (user: UserSearchResult) => void;
  onDelete?: (user: UserSearchResult) => void;
  itemClassName?: string;
  emptyMessage?: string;
}
const UserList: React.FC<UserListProps> = ({
  users,
  onSelect,
  onDelete,
  itemClassName,
  emptyMessage = "No users found"
}) => {
  const handleSelect = React.useCallback((user: UserSearchResult) => {
    if (onSelect) {
      onSelect(user);
    }
  }, [onSelect]);
  if (!users || users.length === 0) {
    return <CommandEmpty style={{
      filter: "drop-shadow"
    }} className="text-center text-sm font-medium text-white">
        <div className="flex px-6 items-center py-[18px] justify-center">
          {emptyMessage}
        </div>
      </CommandEmpty>;
  }
  return <ScrollArea data-sentry-element="ScrollArea" data-sentry-component="UserList" data-sentry-source-file="SearchInput.tsx">
      <CommandGroup className="p-0" data-sentry-element="CommandGroup" data-sentry-source-file="SearchInput.tsx">
        {users.map(user => {
        if (!user) {
          return null;
        }
        return <div key={user?.id} className="flex w-full flex-col">
              <CommandItem className={cn("h-[72px] cursor-pointer items-start w-full flex-1 border-r-4 border-r-transparent bg-[#121320] px-4 py-3 leading-5 text-white aria-selected:bg-[#171824] aria-selected:text-white", users.length > 1 && users.length < 4 && "sm:aria-selected:border-r-[#5069ce]", itemClassName)} onSelect={() => handleSelect(user)}>
                <Link href={user?.username ? `/users/${user.username}` : ""} className={cn("flex w-full items-center justify-between gap-5")}>
                  <div className="flex flex-grow items-center gap-3 font-medium">
                    <UserAvatar user={user} sizes={"45px"} className="size-[45px]" />
                    <div className="flex flex-col gap-1">
                      {/* <span className="text-sm font-bold text-white -tracking-[1%]">
                        {user?.displayName || user.username}
                       </span> */}
                      {user.username && <span className="text-sm font-medium text-white">
                          @{user.username}
                        </span>}
                    </div>
                  </div>

                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="sm:hidden -ml-4">
                    <path d="M13.6709 0.161127L12.9247 0.143627H3.90911L3.90911 1.67098L11.103 1.66833L0.462492 12.3089L1.52315 13.3695L12.1632 2.72952L12.1621 9.92185L13.6895 9.92185L13.6895 0.906241L13.6709 0.161127Z" fill="#E6E6FA" fill-opacity="0.7" />
                  </svg>
                </Link>
                {onDelete && <button onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onDelete(user);
            }} className="ml-2 text-[#e6e6fa] hover:text-white focus:outline-none" aria-label={`Remove ${user.displayName || user.username} from recent searches`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_3574_49412)">
                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0_3574_49412">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>}
              </CommandItem>
            </div>;
      })}
      </CommandGroup>
    </ScrollArea>;
};
interface RecentSearchesProps {
  itemClassName?: string;
  onSelect?: (item: SearchResultType) => void;
}
const RecentSearches: React.FC<RecentSearchesProps> = ({
  itemClassName,
  onSelect
}) => {
  const [recentSearches, setRecentSearches] = useState<SearchResultItem[]>([]);
  const refreshRecentSearches = useCallback(() => {
    // Always get mixed results from all types
    const allRecent = getAllRecentSearches();
    setRecentSearches(allRecent);
  }, []);
  useEffect(() => {
    refreshRecentSearches();
  }, [refreshRecentSearches]);

  // Type-specific handler functions
  const handleBookSelect = useCallback((book: SearchBookResult) => {
    addRecentSearch(book, "books");
    if (onSelect) {
      onSelect(book);
    }
    refreshRecentSearches();
  }, [onSelect, refreshRecentSearches]);
  const handleUserSelect = useCallback((user: UserSearchResult) => {
    addRecentSearch(user, "users");
    if (onSelect) {
      onSelect(user);
    }
    refreshRecentSearches();
  }, [onSelect, refreshRecentSearches]);
  const handleBookDelete = useCallback((book: SearchBookResult) => {
    removeRecentSearch(book?.id as string, "books");
    refreshRecentSearches();
  }, [refreshRecentSearches]);
  const handleUserDelete = useCallback((user: UserSearchResult) => {
    removeRecentSearch(user?.id as string, "users");
    refreshRecentSearches();
  }, [refreshRecentSearches]);
  if (recentSearches.length === 0) {
    return null;
  }
  return <ScrollArea data-sentry-element="ScrollArea" data-sentry-component="RecentSearches" data-sentry-source-file="SearchInput.tsx">
      {/* <div className="px-3 py-2 text-[#e6e6fa]/50 text-xs font-medium border-b border-[#bdbdbd]/10">
        Recent Searches
       </div> */}

      <CommandGroup className="p-0" data-sentry-element="CommandGroup" data-sentry-source-file="SearchInput.tsx">
        {recentSearches.map(item => {
        if (!item || !item.id) {
          return null;
        }

        // Render book item
        if ("title" in item) {
          const book = item as SearchBookResult;
          return <div key={`book-${book.id}`} className="flex w-full flex-col">
                <CommandItem className={cn("h-[96px] cursor-pointer items-start w-full flex-1 border-b border-b-[#bdbdbd]/10 border-r-4 border-r-transparent bg-[#121320] px-4 py-3 leading-5 text-white aria-selected:bg-[#171824] aria-selected:text-white", "sm:aria-selected:border-r-[#5069ce]", itemClassName)} onSelect={() => handleBookSelect(book)}>
                  <Link href={book?.slug ? `/books/${book.slug}` : ""} className={cn("flex w-full items-center justify-between gap-5")}>
                    <div className="flex flex-grow items-start gap-4 font-medium">
                      <div className="h-[72px] w-[48px] aspect-[1/1.5] rounded-[4px]">
                        <BookCover fallbackClassName="rounded-[4px]" book={book} useDivInsteadOfLink={true} sizes="48px" fallBackSize={28} className="h-full w-full rounded-[4px] object-cover object-center" style={{
                      filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.35))"
                    }} />
                      </div>
                      <div className="flex flex-col mt-2 gap-2">
                        <span className="text-sm font-bold text-white -tracking-[1%]">
                          {book?.title && book.title.length > 27 ? `${book?.title.slice(0, 27)}...` : book.title}
                        </span>
                        {book.authors && <span className="text-xs font-medium text-[#e6e6fa]/70">
                            {book.authors}
                          </span>}
                      </div>
                    </div>
                  </Link>
                  <button onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                handleBookDelete(book);
              }} className="ml-2 text-[#e6e6fa] hover:text-white focus:outline-none" aria-label={`Remove ${book.title} from recent searches`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_3574_49412)">
                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0_3574_49412">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </CommandItem>
              </div>;
        }

        // Render user item
        if ("username" in item) {
          const user = item as UserSearchResult;
          return <div key={`user-${user.id}`} className="flex w-full flex-col">
                <CommandItem className={cn("h-[72px] cursor-pointer items-start w-full flex-1 border-b border-b-[#bdbdbd]/10 border-r-4 border-r-transparent bg-[#121320] px-4 py-3 leading-5 text-white aria-selected:bg-[#171824] aria-selected:text-white", "sm:aria-selected:border-r-[#5069ce]", itemClassName)} onSelect={() => handleUserSelect(user)}>
                  <Link href={user?.username ? `/users/${user.username}` : ""} className={cn("flex w-full items-center justify-between gap-5")}>
                    <div className="flex flex-grow items-center gap-3 font-medium">
                      <UserAvatar user={user} sizes={"45px"} className="size-[45px]" />
                      <div className="flex flex-col gap-1">
                        {user.username && <span className="text-sm font-medium text-white">
                            @{user.username}
                          </span>}
                      </div>
                    </div>
                  </Link>
                  <button onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                handleUserDelete(user);
              }} className="ml-2 text-[#e6e6fa] hover:text-white focus:outline-none" aria-label={`Remove ${user.displayName || user.username} from recent searches`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_3574_49412)">
                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0_3574_49412">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </CommandItem>
              </div>;
        }
        return null;
      })}
      </CommandGroup>
    </ScrollArea>;
};