/* eslint-disable @next/next/no-img-element */
"use client";

import React from "react";
import { SearchBookResult } from "@/graphql/generated/types";
import { SearchResultType } from "./SearchInput";
import { cn } from "@/lib/utils";
import SearchInput from "./SearchInput";
interface SearchBooksInputProps {
  placeholder?: string;
  addBook?: (book: SearchBookResult) => void;
  className?: string;
  hideRecentSearches?: boolean;
  containerClassName?: string;
  parentClassName?: string;
  itemClassName?: string;
  commandListClassName?: string;
  iconSize?: number;
  lockBackgroundScroll?: boolean;
  focusInput?: boolean;
  excludeUsers?: boolean;
  showAllResults?: boolean;
  disableLink?: boolean;
}
const SearchBooksInput: React.FC<SearchBooksInputProps> = ({
  placeholder,
  addBook,
  hideRecentSearches,
  className,
  containerClassName,
  parentClassName,
  itemClassName,
  commandListClassName,
  lockBackgroundScroll,
  focusInput,
  excludeUsers = true,
  showAllResults = false,
  disableLink = false
}) => {
  const handleSelect = (item: SearchResultType) => {
    if (addBook && "title" in item) {
      addBook(item as SearchBookResult);
    }
  };
  return <SearchInput placeholder={placeholder || "Search by Title"} onSelect={handleSelect} className={cn(className)} hideRecentSearches={hideRecentSearches} containerClassName={containerClassName} parentClassName={parentClassName} itemClassName={itemClassName} commandListClassName={commandListClassName} lockBackgroundScroll={lockBackgroundScroll} focusInput={focusInput} searchType="books" excludeUsers={excludeUsers} showAllResults={showAllResults} disableLink={disableLink} data-sentry-element="SearchInput" data-sentry-component="SearchBooksInput" data-sentry-source-file="SearchBooksInput.tsx" />;
};
export default React.memo(SearchBooksInput);