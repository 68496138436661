"use client";

import React from "react";
import { Dialog, DialogClose, DialogContent, DialogHeader, DialogTrigger } from "../ui/dialog";
import { Button } from "../ui/button";
import ButtonLoader from "../shared/ButtonLoader";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../ui/form";
import { Input } from "../ui/input";
import { useMutation } from "@apollo/client";
import { ImportGoodreadsBookDocument } from "@/graphql/generated/types";
import { GraphQLFormattedError } from "graphql";
interface BanError extends GraphQLFormattedError {
  code?: string;
  reason?: string;
}
const BAN_REASONS: Record<string, string> = {
  SERIES_RANGE: "This book is part of a multi-book series, box set, or collection, which we don't support.",
  NICHE: "This book has too few reviews to be imported.",
  GRAPHIC_NOVEL: "We currently do not support importing graphic novels.",
  TEXTBOOKS: "We currently do not support importing textbooks.",
  COMICS: "We currently do not support importing comics.",
  MANGA: "We currently do not support importing manga.",
  MANHWA: "We currently do not support importing manhwa.",
  PICTURE_BOOKS: "We currently do not support importing picture books.",
  REFERENCE: "We currently do not support importing reference books.",
  TECHNICAL: "We currently do not support importing technical books.",
  ART_BOOKS: "We currently do not support importing art books.",
  COFFEE_TABLE: "We currently do not support importing coffee table books.",
  LIGHT_NOVEL: "We currently do not support importing light novels.",
  COOKBOOK: "We currently do not support importing cook books."
};
const goodReadsUrlSchema = z.object({
  url: z.string().url({
    message: "Please enter a valid Goodreads book URL"
  }).refine(url => url.includes("goodreads.com/book"), {
    message: "Please enter a valid Goodreads book URL"
  })
});
const AddBookDialog = ({
  trigger
}: {
  trigger?: React.ReactNode;
}) => {
  const form = useForm<z.infer<typeof goodReadsUrlSchema>>({
    resolver: zodResolver(goodReadsUrlSchema),
    mode: "onChange",
    defaultValues: {
      url: ""
    }
  });
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [importGoodreadsBook] = useMutation(ImportGoodreadsBookDocument, {
    errorPolicy: "all"
  });
  const [loading, setLoading] = React.useState(false);
  const [isFinished, setIsFinished] = React.useState(false);
  const onSubmit = async (values: z.infer<typeof goodReadsUrlSchema>) => {
    setLoading(true);
    setErrorMessage(null);
    try {
      const {
        errors
      } = await importGoodreadsBook({
        variables: {
          url: values.url
        }
      });
      if (errors && errors.length > 0) {
        // Look for a banned error among the returned errors.
        const error = errors[0] as BanError;
        const bannedError = error?.code;
        if (bannedError === "BANNED") {
          const reason = error?.reason;
          const bannedMsg = reason && BAN_REASONS[reason] ? BAN_REASONS[reason] : "We don't currently support adding this book.";
          setErrorMessage(bannedMsg);
        } else {
          console.error("Other GraphQL errors:", errors);
          setErrorMessage("An error occurred while importing the book.");
        }
        setLoading(false);
        return;
      }

      // Successful import
      setIsFinished(true);
    } catch (err) {
      console.error("Unexpected error:", err);
      setErrorMessage("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (dialogOpen) {
      setIsFinished(false);
      setErrorMessage(null);
      form.reset();
    }
  }, [dialogOpen, form]);
  return <Dialog open={dialogOpen} onOpenChange={setDialogOpen} data-sentry-element="Dialog" data-sentry-component="AddBookDialog" data-sentry-source-file="AddBookDialog.tsx">
      <DialogTrigger asChild data-sentry-element="DialogTrigger" data-sentry-source-file="AddBookDialog.tsx">
        {trigger ? trigger : <Button type="button" variant="link" className="p-0 text-sm font-medium text-white/40">
            <span>Add Book</span>
          </Button>}
      </DialogTrigger>
      <DialogContent hideCloseButton className="max-sm:max-w-[336px] gap-0 sm:max-w-[437px] p-0 border-none rounded-[16px] bg-[#10111e]" data-sentry-element="DialogContent" data-sentry-source-file="AddBookDialog.tsx">
        <DialogHeader className="flex border-b border-[#1E1F2B] flex-row pl-6 pr-3 pt-3 pb-3 justify-between space-y-0 items-center gap-2" data-sentry-element="DialogHeader" data-sentry-source-file="AddBookDialog.tsx">
          <p className="w-fit tracking-[-0.01em] flex items-center gap-2 max-sm:p-0 text-lg text-white font-bold sm:font-semibold">
            {isFinished ? <span>Submitted Successfully!🎉</span> : <>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.0584 0.1875H6.32328C6.23239 0.180051 6.14124 0.176142 6.05005 0.175781C4.13689 0.175781 2.58594 1.72669 2.58594 3.63984C2.586 3.73294 2.58966 3.82509 2.59691 3.91631H2.58594V22.1037C2.58594 23.0205 3.32914 23.7637 4.24597 23.7637H6.15064V19.9584C6.31648 20.0092 6.48898 20.035 6.66242 20.0348H20.6153C21.5783 20.0348 22.359 19.2542 22.359 18.2911V2.48812C22.359 1.21753 21.329 0.1875 20.0584 0.1875Z" fill="#5069CE" />
                  <path d="M21.3731 3.18417C21.3731 2.1533 20.4005 1.31048 19.1719 1.24655V1.24219H6.05234C5.09577 1.24219 4.32031 1.836 4.32031 2.56847C4.32031 3.30094 5.09577 3.89475 6.05234 3.89475V3.91748H15.5587V22.2443L19.0219 22.6508C20.3669 22.6508 21.3731 19.9895 21.3731 18.6752V3.27652H21.3703C21.372 3.24591 21.3731 3.21516 21.3731 3.18417Z" fill="#DCE2E2" />
                  <path d="M18.3052 3.91797H6.14844V23.7653H18.3052C19.2545 23.7653 20.024 22.9958 20.024 22.0465V5.63683C20.024 4.68752 19.2545 3.91797 18.3052 3.91797Z" fill="#7187E1" />
                  <path d="M15.6333 11.2695H9.40156C9.02877 11.2695 8.72656 10.9672 8.72656 10.5945V8.24531C8.72656 7.87252 9.02877 7.57031 9.40156 7.57031H15.6333C16.0061 7.57031 16.3083 7.87252 16.3083 8.24531V10.5945C16.3083 10.9672 16.0061 11.2695 15.6333 11.2695Z" fill="#32434F" />
                </svg>

                <span>Add Book to Kaguya</span>
              </>}
          </p>

          <DialogClose asChild data-sentry-element="DialogClose" data-sentry-source-file="AddBookDialog.tsx">
            <Button type="button" variant="link" className="p-3 bg-transparent border border-[#1e1f2b] hover:bg-[#1e1f2b] transition-colors duration-200 rounded-full font-normal text-white flex items-center size-11" data-sentry-element="Button" data-sentry-source-file="AddBookDialog.tsx">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="AddBookDialog.tsx">
                <path fillRule="evenodd" clipRule="evenodd" d="M16.0237 3.97792C16.3966 4.35081 16.3966 4.9554 16.0237 5.32829L5.32927 16.0227C4.95637 16.3956 4.35179 16.3956 3.97889 16.0227C3.60599 15.6498 3.60599 15.0453 3.97889 14.6723L14.6733 3.97792C15.0463 3.60502 15.6508 3.60502 16.0237 3.97792Z" fill="white" data-sentry-element="path" data-sentry-source-file="AddBookDialog.tsx" />
                <path fillRule="evenodd" clipRule="evenodd" d="M3.97889 3.97792C4.35179 3.60502 4.95637 3.60502 5.32927 3.97792L16.0237 14.6723C16.3966 15.0453 16.3966 15.6498 16.0237 16.0227C15.6508 16.3956 15.0463 16.3956 14.6733 16.0227L3.97889 5.32829C3.60599 4.9554 3.60599 4.35081 3.97889 3.97792Z" fill="white" data-sentry-element="path" data-sentry-source-file="AddBookDialog.tsx" />
              </svg>
            </Button>
          </DialogClose>
        </DialogHeader>
        <div className="flex flex-col gap-4 p-6">
          <p className="text-[#868f9d] text-sm font-normal">
            {isFinished ? "Thanks for expanding our library! The book may take a moment to appear in search." : "Find the book on Goodreads and paste the link below"}
          </p>

          {!isFinished && <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <FormField control={form.control} name="url" render={({
              field
            }) => <FormItem>
                      <FormControl>
                        <Input placeholder="htts://www.goodreads.com/book/show/25019.The_Professor..." className="bg-[#161724] w-full placeholder:text-white/20 placeholder:text-xs h-11 pl-3.5 pr-8 sm:pr-4 py-3.5 text-xs text-[#e6e6fa]/90 sm:text-white font-normal rounded-[100px] border border-none" id="url" {...field} />
                      </FormControl>
                      <FormMessage role="alert" className="!mt-0" />
                    </FormItem>} />

                {errorMessage && <p className="text-red-400 mt-2 text-sm font-medium">
                    {errorMessage}
                  </p>}
                <div className="w-full flex mt-6 sm:mt-4 justify-end">
                  <Button type="submit" variant="primary" className="px-4 py-3 justify-end text-white ml-0 w-fit min-w-[74px] text-sm font-normal sm:font-normal flex items-center gap-2 rounded-[8px] h-[44px] sm:h-[41px]" disabled={loading}>
                    {loading ? <span className="flex items-center justify-center w-full gap-1.5 px-0 text-xs">
                        <ButtonLoader />
                      </span> : "Submit"}
                  </Button>
                </div>
              </form>
            </Form>}

          {isFinished && <div className="w-full flex mt-2 justify-end">
              <Button type="button" onClick={() => setDialogOpen(false)} variant="primary" className="px-4 py-3 justify-end text-white ml-0 w-fit min-w-[74px] text-sm font-normal sm:font-normal flex items-center gap-2 rounded-[8px] h-[41px]">
                Okay! got it
              </Button>
            </div>}
        </div>
      </DialogContent>
    </Dialog>;
};
export default AddBookDialog;