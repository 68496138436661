import { SearchBookResult, User } from "@/graphql/generated/types"

export type SearchType = "books" | "users" | "authors" | "series"
export type SearchResultItem = SearchBookResult | UserSearchResult

export interface UserSearchResult {
  __typename?: "User"
  id?: string | null
  username?: string | null
  displayName?: string | null
  avatarUrls?: User["avatarUrls"]
}

const RECENT_SEARCHES_PREFIX = "recentSearches"

export function getRecentSearches<T extends SearchResultItem>(
  type: SearchType = "books"
): T[] {
  if (typeof window === "undefined") return []
  const key = `${RECENT_SEARCHES_PREFIX}_${type}`
  const data = localStorage.getItem(key)
  if (!data) return []
  try {
    return JSON.parse(data) as T[]
  } catch (error) {
    console.error(`Failed to parse recent ${type} searches`, error)
    return []
  }
}

// Get mixed recent searches for all types
export function getAllRecentSearches(): SearchResultItem[] {
  if (typeof window === "undefined") return []

  const types: SearchType[] = ["books", "users", "authors", "series"]
  let allSearches: SearchResultItem[] = []

  // Get searches from all types
  types.forEach((type) => {
    try {
      const key = `${RECENT_SEARCHES_PREFIX}_${type}`
      const data = localStorage.getItem(key)
      if (data) {
        const searches = JSON.parse(data) as SearchResultItem[]
        allSearches = [...allSearches, ...searches]
      }
    } catch (error) {
      console.error(`Failed to parse recent ${type} searches`, error)
    }
  })

  // Sort by implicit timestamp (newest first) - we'll use the position in each array
  // Remove duplicates in case the same item appears in multiple arrays
  const seen = new Set<string>()
  const uniqueSearches = allSearches.filter((item) => {
    if (!item?.id) return false
    const duplicate = seen.has(item.id)
    if (!duplicate) seen.add(item.id)
    return !duplicate
  })

  return uniqueSearches.slice(0, MAX_RECENT_SEARCHES)
}

export function addRecentSearch<T extends SearchResultItem>(
  item: T,
  type: SearchType = "books"
): void {
  if (typeof window === "undefined") return
  const key = `${RECENT_SEARCHES_PREFIX}_${type}`
  const recentSearches = getRecentSearches<T>(type)

  const existingIndex = recentSearches.findIndex((b) => b.id === item?.id)
  if (existingIndex !== -1) {
    recentSearches.splice(existingIndex, 1)
  }
  recentSearches.unshift(item)
  const recentSearchesToStore = recentSearches.slice(0, MAX_RECENT_SEARCHES)
  localStorage.setItem(key, JSON.stringify(recentSearchesToStore))
}

export function removeRecentSearch(
  itemId: string,
  type: SearchType = "books"
): void {
  if (typeof window === "undefined") return
  const key = `${RECENT_SEARCHES_PREFIX}_${type}`
  const recentSearches = getRecentSearches(type)
  const updatedSearches = recentSearches.filter((item) => item?.id !== itemId)
  try {
    localStorage.setItem(key, JSON.stringify(updatedSearches))
  } catch (error) {
    console.error(`Failed to remove recent ${type} search`, error)
  }
}

export function clearRecentSearches(type: SearchType = "books"): void {
  if (typeof window === "undefined") return
  const key = `${RECENT_SEARCHES_PREFIX}_${type}`
  localStorage.removeItem(key)
}

export function clearAllRecentSearches(): void {
  if (typeof window === "undefined") return

  const types: SearchType[] = ["books", "users", "authors", "series"]
  types.forEach((type) => {
    const key = `${RECENT_SEARCHES_PREFIX}_${type}`
    localStorage.removeItem(key)
  })
}

const MAX_RECENT_SEARCHES = 10
